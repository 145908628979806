import React, {useState, useEffect, useCallback, useRef} from 'react'

import Table from '../components/Table';
import Chart from '../components/Chart';

import NewTable from '../components/NewTable';

import '../style/general.css';

const All = () => {
  // STATE
  const interval = useRef(null);
  const usersInterval = useRef(null);

  const [data, setData] = useState({
    dashboard: {
      id: 'dashboard',
      status: 'OFFLINE',
      name: 'Dashboard - FRONTEND',
      domain: 'dashboard.curoflow.se',
      users: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'front'
    },
    dashboardAPI: {
      id: 'dashboardApi',
      status: 'OFFLINE',
      name: 'Dashboard - BACKEND',
      domain: 'dashboardapi.curoflow.se',
      users: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'back'
    },

    /*video: {
      id: 'video',
      status: 'OFFLINE',
      name: 'Video - FRONTEND',
      domain: 'video.curoflow.se',
      users: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'front'
    },
    videoApi: {
      id: 'videoApi',
      status: 'OFFLINE',
      name: 'Video - BACKEND',
      domain: 'video-api.curoflow.se',
      users: 'N/A',
      guests: 'N/A',
      cpu: 'N/A',
      memory: 'N/A',
      ts: `Checking...`,
      type: 'back'
    },*/

    // testDashboard: {
    //   id: 'testDashboard',
    //   status: 'ONLINE',
    //   domain: 'http://localhost:5002',
    //   users: 'N/A',
    //   cpu: 'N/A',
    //   memory: 'N/A',
    //   ts: `Checking...`
    // },
    // testApi: {
    //   id: 'testApi',
    //   status: 'ONLINE',
    //   domain: 'http://localhost:4005',
    //   users: 'N/A',
    //   cpu: 'N/A',
    //   memory: 'N/A',
    //   ts: `Checking...`
    // },
  });

  const [dashboardUsers, setDashboardUsers] = useState([]);
  const [videoUsers, setVideoUsers] = useState([]);
  // const [testUsers, setTestUsers] = useState([]);

  // FETCH FUNCTIONS
  const fetchDashboard = useCallback(() => {
    fetch('https://dashboard.curoflow.se/')
    .then(res => {
      if (res && res.status >= 200 && res.status < 300) {
        setData(prev => ({
          ...prev,
          dashboard: {
            ...prev.dashboard,
            status: 'ONLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }))
      } else {
        setData(prev => ({
          ...prev,
          dashboard: {
            ...prev.dashboard,
            status: 'OFFLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
      }
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        dashboard: {
          ...prev.dashboard,
          status: 'OFFLINE',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }))
    });
  }, []);

  const fetchDashboardApi = useCallback(() => {
    fetch('https://dashboardapi.curoflow.se/system-monitoring/stats')
    .then(res => {
      if (res && res.ok) {
        return res.json();
      }
    })
    .then(data => {
      if (!data) {
        setData(prev => ({
          ...prev,
          dashboardAPI: {
            ...prev.dashboardAPI,
            status: 'OFFLINE',
            users: 'N/A',
            cpu: 'N/A',
            memory: 'N/A',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
        return;
      }
      setData(prev => ({
        ...prev,
        dashboardAPI: {
          ...prev.dashboardAPI,
          status: 'ONLINE',
          users: data?.totalNumberOfUsers || 'N/A',
          cpu: data?.cpuPercent?.toFixed(2) + ' %' || 'N/A',
          memory: data?.memoryPercent?.toFixed(2) + ' %' || 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        dashboardAPI: {
          ...prev.dashboardAPI,
          status: 'OFFLINE',
          users: 'N/A',
          cpu: 'N/A',
          memory: 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    });
  }, []);

/*  const fetchVideo = useCallback(() => {
    fetch('https://video.curoflow.se/')
    .then(res => {
      if (res && res.status >= 200 && res.status < 300) {
        setData(prev => ({
          ...prev,
          video: {
            ...prev.video,
            status: 'ONLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }))
      } else {
        setData(prev => ({
          ...prev,
          video: {
            ...prev.video,
            status: 'OFFLINE',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
      }
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        video: {
          ...prev.video,
          status: 'OFFLINE',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }))
    });
  }, []);
*/
/*
  const fetchVideoApi = useCallback(() => {
    fetch('https://video-api.curoflow.se/system-monitoring/stats')
    .then(res => {
      if (res && res.ok) {
        return res.json();
      }
    })
    .then(data => {
      if (!data) {
        setData(prev => ({
          ...prev,
          videoApi: {
            ...prev.videoApi,
            status: 'OFFLINE',
            users: 'N/A',
            cpu: 'N/A',
            memory: 'N/A',
            ts: `${new Date().toLocaleTimeString('sv-SV')}`
          }
        }));
        return;
      }
      setData(prev => ({
        ...prev,
        videoApi: {
          ...prev.videoApi,
          status: 'ONLINE',
          users: data?.totalNumberOfUsers || 'N/A',
          guests: data?.totalNumberOfGuest || 0,
          cpu: data?.cpuPercent?.toFixed(2) + ' %' || 'N/A',
          memory: data?.memoryPercent?.toFixed(2) + ' %' || 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    })
    .catch(err => {
      setData(prev => ({
        ...prev,
        videoApi: {
          ...prev.videoApi,
          status: 'OFFLINE',
          users: 'N/A',
          cpu: 'N/A',
          memory: 'N/A',
          ts: `${new Date().toLocaleTimeString('sv-SV')}`
        }
      }));
    });
  }, []);
*/

  // const fetchTestDashboard = useCallback(() => {
  //   fetch('http://localhost:5002/')
  //   .then(res => {
  //     if (res && res.status >= 200 && res.status < 300) {
  //       console.log(res);
  //       setData(prev => ({
  //         ...prev,
  //         testDashboard: {
  //           ...prev.testDashboard,
  //           status: 'ONLINE',
  //           ts: `${new Date().toLocaleDateString('sv-SV')}  ${new Date().toLocaleTimeString('sv-SV')}`
  //         }
  //       }))
  //     } else {
  //       setData(prev => ({
  //         ...prev,
  //         testDashboard: {
  //           ...prev.testDashboard,
  //           status: 'OFFLINE',
  //           ts: `${new Date().toLocaleDateString('sv-SV')}  ${new Date().toLocaleTimeString('sv-SV')}`
  //         }
  //       }));
  //     }
  //   })
  //   .catch(err => {
  //     setData(prev => ({
  //       ...prev,
  //       testDashboard: {
  //         ...prev.testDashboard,
  //         status: 'OFFLINE',
  //         ts: `${new Date().toLocaleDateString('sv-SV')}  ${new Date().toLocaleTimeString('sv-SV')}`
  //       }
  //     }))
  //   });
  // }, []);

  // const fetchTestApi = useCallback(() => {
  //   fetch('http://localhost:4005/system-monitoring/stats')
  //   .then(res => {
  //     if (res && res.ok) {
  //       return res.json();
  //     }
  //   })
  //   .then(data => {
  //     if (!data) {
  //       setData(prev => ({
  //         ...prev,
  //         testApi: {
  //           ...prev.testApi,
  //           status: 'OFFLINE',
  //           users: 'N/A',
  //           cpu: 'N/A',
  //           memory: 'N/A',
  //           ts: `${new Date().toLocaleDateString('sv-SV')}  ${new Date().toLocaleTimeString('sv-SV')}`
  //         }
  //       }));
  //       return;
  //     }
  //     setData(prev => ({
  //       ...prev,
  //       testApi: {
  //         ...prev.testApi,
  //         status: 'ONLINE',
  //         users: data?.totalNumberOfUsers || 'N/A',
  //         cpu: data?.cpuPercent?.toFixed(2) + ' %' || 'N/A',
  //         memory: data?.memoryPercent?.toFixed(2) + ' %' || 'N/A',
  //         ts: `${new Date().toLocaleDateString('sv-SV')}  ${new Date().toLocaleTimeString('sv-SV')}`
  //       }
  //     }));
  //   })
  //   .catch(err => {
  //     setData(prev => ({
  //       ...prev,
  //       testApi: {
  //         ...prev.testApi,
  //         status: 'OFFLINE',
  //         users: 'N/A',
  //         cpu: 'N/A',
  //         memory: 'N/A',
  //         ts: `${new Date().toLocaleDateString('sv-SV')}  ${new Date().toLocaleTimeString('sv-SV')}`
  //       }
  //     }));
  //   });
  // }, []);

  const getDashboardUsers = useCallback(() => {
    fetch('https://dashboardapi.curoflow.se/system-monitoring/users')
    .then(res => res.json())
    .then(data => {
      if (data) {
        setDashboardUsers(data);
      }
    })
    .catch(err => console.log(err));
  }, []);
/*
  const getVideoUsers = useCallback(() => {
    fetch('https://video-api.curoflow.se/system-monitoring/users')
    .then(res => res.json())
    .then(data => {
      if (data) {
        setVideoUsers(data);
      }
    })
    .catch(err => console.log(err));
  }, []);
*/
  // const getTestUsers = useCallback(() => {
  //   fetch('http://localhost:4005/system-monitoring/users')
  //   .then(res => res.json())
  //   .then(data => {
  //     if (data) {
  //       setTestUsers(data);
  //     }
  //   })
  //   .catch(err => console.log(err));
  // }, []);

  // EFFECTS AND INTERVAL
  useEffect(() => {
    if (interval?.current) {
      clearInterval(interval.current);
      interval.current = null;
    }

    if (usersInterval?.current) {
      clearInterval(usersInterval.current);
      usersInterval.current = null;
    }

    fetchDashboard();
    fetchDashboardApi();
    // fetchVideo();
    // fetchVideoApi();
    // fetchTestDashboard();
    // fetchTestApi();

    // Users data - fire only once - no interval!
    getDashboardUsers();
    // getVideoUsers();
    // getTestUsers();

    interval.current = setInterval(() => {
      fetchDashboard();
      fetchDashboardApi();
      // fetchVideo();
      // fetchVideoApi();
      // fetchTestDashboard();
      // fetchTestApi();
    }, 10 * 1000);

    usersInterval.current = setInterval(() => {
      getDashboardUsers();
      // getVideoUsers();
    }, 10 * 60 * 1000);

    return () => {
      clearInterval(interval.current);
      clearInterval(usersInterval.current);
    }
  }, []);

  

  // RENDER
  // return (<div className='container'>
  //     <Table title='Curoflow System Monitoring - All Platforms' data={data} />
  //     <Chart data={dashboardUsers} title='New dashboard users' />
  //     <Chart data={videoUsers} title='New video users' />
  //     {/* <Chart data={testUsers} title='New test users' /> */}
  //   </div>
  // )
  return (<div className='container'>
      <h1>Curoflow System Monitoring</h1>
      <h2>All Platforms</h2>
      <div className='all-container'>
        <div className='TABLE-container'>
          <NewTable data={data} />
        </div>
        <div className='CHARTS-container'>
          <Chart className='all-charts' data={dashboardUsers} title='New dashboard users' />
          {/*<Chart className='all-charts' data={videoUsers} title='New video users' />*/}
        </div>
      </div>
      
      
      {/* <Chart data={testUsers} title='New test users' /> */}
    </div>
  )
}

export default All
